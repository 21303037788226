		  
import { SelectionModel } from "@angular/cdk/collections";
import { FlatTreeControl } from "@angular/cdk/tree";
import { NestedTreeControl } from "@angular/cdk/tree";
import { Component, Injectable, OnInit, ViewChild,ElementRef, HostListener } from "@angular/core";
import {MatTreeFlatDataSource,MatTreeFlattener, MatTreeNestedDataSource} from "@angular/material/tree";
import { BehaviorSubject } from "rxjs";
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Observable, of } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatSelect } from "@angular/material/select";
import { MatOption } from "@angular/material/core";
import { SearchService } from "src/app/Services/search.service";
import * as XLSX from 'xlsx-js-style';
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { HttpClient } from '@angular/common/http';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { now } from "moment";
import { MatInput } from "@angular/material/input";
import { ht, tr } from "date-fns/locale";
import { style } from "@angular/animations";
import { saveAs } from 'file-saver';
import { MatSelectFilterModule } from 'mat-select-filter';
import { MatOptionSelectionChange} from '@angular/material/core';


export class EmployeeDetails {
  ActivityId : number;
  SourceOfActivity: string;
  CatBatReference: number;
  CPReference: JSON;
  MIReference: JSON;
  BRReference: JSON;
  Tool: string;
  ApplicableToBusiness: string;
  Activity: string;
  Responsible: JSON;
  accountable: JSON;  
  Consult: JSON;
  Inform: JSON;
  Remark: JSON;
  ActivityFormingPartOfLat: string;
  BusinessAreaID: number;
  BusinessArea: string;
  businessAreaCode: string;
  BgColor:string;
  FontColor:string;
  FontWeight:string;
}


export class Preamble{
  id:number;
  topic:string;
  remarks: string;
}

export class ReadingThisDocument{
  id:number;
  column:string;
  description: string;
}
export class NoteList{
  id:number;
  businessUnitId:number;
  businessUnitCode:string;
  note: string;
}
export class Entity{
  id:number;
  code:string;
  name: string;
  status: boolean;
}
/**
 * Node for to-do item
 */
export class TodoItemNode {
  children: TodoItemNode[];
  id:number;
  parentId: string;
  text: string;
  level: number;
  expandable: boolean;
  selected?: boolean;
}

/** Flat to-do item node with expandable and level information */
export class TodoItemFlatNode {
  id:number;
  text: string;
  parentId: string;
  level: number;
  expandable: boolean;
  selected?: boolean;
  children: TodoItemFlatNode[];
}

export interface BusinessAreaMST{
  id:number;
  name: string;
  code:string;
}
export interface DivisionMST{
  id:number;
  name: string;
}
export interface DesignationMST{
  id:number;
  name: string;
}

export interface WorkSheetTableConfig{
  displayedColumns: string[];
  dataSource: MatTableDataSource<EmployeeDetails>;
  tableId: string;
}
@Injectable()
export class ChecklistDatabase  {
  dataChange = new BehaviorSubject<TodoItemNode[]>([]);

  get data(): TodoItemNode[] { return this.dataChange.value; }

  constructor( private searchser: SearchService) {
    this.initialize();
  }




  initialize() {
    
    this.searchser.getSearchActivityList(1).subscribe((data1: any[])=>{
      console.log(data1);
      const data = this.treeConstruct(data1);
    this.dataChange.next(data);
    })  
  }




  
  treeConstruct(treeData) {
    let constructedTree = [];
    for (let i of treeData) {
      let treeObj = i;
      let assigned = false;
      this.constructTree(constructedTree, treeObj, assigned)
    }
    return constructedTree;
  }
constructTree(constructedTree, treeObj, assigned) {
if (treeObj.parentId == null) {
      treeObj.children = [];
      constructedTree.push(treeObj);
      return true;
    } else if (treeObj.parentId == constructedTree.id) {
      treeObj.children = [];
      constructedTree.children.push(treeObj);
      return true;
    }
    else {
      if (constructedTree.children != undefined) {
        for (let index = 0; index < constructedTree.children.length; index++) {
          let constructedObj = constructedTree.children[index];
          if (assigned == false) {
            assigned = this.constructTree(constructedObj, treeObj, assigned);
          }
        }
      } else {
        for (let index = 0; index < constructedTree.length; index++) {
          let constructedObj = constructedTree[index];
          if (assigned == false) {
            assigned = this.constructTree(constructedObj, treeObj, assigned);
          }
        }
      }
      return false;
    }
  }


  
    /** Add an item to to-do list */
    insertItem(parent: TodoItemNode, name: string) {
      if (parent.children) {
        parent.children.push({text: name} as TodoItemNode);
        this.dataChange.next(this.data);
      }
    }
  
    updateItem(node: TodoItemNode, name: string) {
      node.text = name;
      this.dataChange.next(this.data);
    }



}

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css'] ,
  providers: [ChecklistDatabase]
})
export class SearchComponent implements OnInit {

  divisionList: DivisionMST[]=[];
  businessList: BusinessAreaMST[]=[];
  designationList: DesignationMST[]=[];
  entityList: Entity[]=[];
  selectEntityFormControl = new FormControl();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort,{static:true}) sort: MatSort;
  @ViewChild('select') select: MatSelect;
  @ViewChild('selectDivision') selectDivision: MatSelect;
  @ViewChild('selectDesignation') selectDesignation: MatSelect;
  @ViewChild('txtTreeViewSelected') txtTreeViewSelected: ElementRef; 
 
  @ViewChild('actualDataToDownload') actualDataToDownload :ElementRef;
  searchValueFormControl = new FormControl();
  excelData_Preamble: any[];
  excelData_Reading: any[];
  workbook_Preamble: XLSX.WorkBook;
  workbook_Reading: XLSX.WorkBook;
  combined_workbook: XLSX.WorkBook;
  
  divisions= new FormControl('');
  designations = new FormControl('');
  allSelected=false;
  allSelectedDivision=false;
  allSelectedDesignation=false;
  isMultipleSelected: boolean = false;
  isMultipleSelectedDivision: boolean = false;
  isMultipleSelectedDesignation: boolean = false;
  formEntity: FormGroup;
  displayedColumns_Dummy: string[] = ['BgColor','FontColor','FontWeight','BusinessArea','SourceOfActivity','CatBatReference','CPReference','MIReference','BRReference','Tool','ApplicableToBusiness','Activity','Responsible', 'Accountable' , 'Consult', 'Inform','Remark','ActivityFormingPartOfLat'];
  displayedColumns: string[] = ['BusinessArea','CatBatReference','CPReference','MIReference','Tool','ApplicableToBusiness','Activity','Responsible', 'Accountable' , 'Consult', 'Inform','Remark','ActivityFormingPartOfLat'];
  displayedColumns_Preamble: string[] = ['id','topic','remarks'];
  displayedColumns_Reading: string[] = ['id','column','description'];
  selectedEntityValue = [];
  dataSource1 : MatTableDataSource<EmployeeDetails>;
  dataSource_Preamble : MatTableDataSource<Preamble>;
  dataSource_Reading : MatTableDataSource<ReadingThisDocument>;
  dataSource_Note : MatTableDataSource<NoteList>;
  dummyDataSource: MatTableDataSource<EmployeeDetails>;
  latestVersion: string;
  rows=[];
  searchValue : string  = ''; 
  getSelectedItemsList : string  = "Select Activity!";
  formInstance: any;
  jsonData_Preamble:any[];
  @ViewChild('TABLE', { static: false }) TABLE: ElementRef;
  @ViewChild('tree') tree;
  selectedControl= new FormControl();  searchString = ''
  dataChange = new BehaviorSubject<TodoItemNode[]>([]);

  get data(): TodoItemNode[] { return this.dataChange.value; }
  searchValueText: string = '';
  @ViewChild('searchBusinessArea') searchBusinessAreaTextBox: ElementRef;
  selectBusinessAreaFormControl = new FormControl([]);
  searchBusinessAreaTextboxControl = new FormControl();
  selectedBusinessAreaValues = [];
  businessAreaFilteredOptions: Observable<BusinessAreaMST[]>= of(this.businessList);
  selectedBusinessAreaId= new FormControl([]);
  selectedBusinessAreaValue= new FormControl([]);

  
  @ViewChild('searchDivision') searchDivisionTextBox: ElementRef;
  selectedDivisionId= new FormControl([]);
  selectedDivisionValue= new FormControl([]);
  selectDivisionFormControl = new FormControl([]);
  searchDivisionTextboxControl = new FormControl();
  selectedDivisionValues = [];
  divisionFilteredOptions: Observable<DivisionMST[]>= of(this.divisionList);

  @ViewChild('searchDesignation') searchDesignationTextBox: ElementRef;
  selectedDesignationId= new FormControl([]);
  selectedDesignationValue= new FormControl([]);
  selectDesignationFormControl = new FormControl([]);
  searchDesignationTextboxControl = new FormControl();
  selectedDesignationValues = [];
  designationFilteredOptions: Observable<DesignationMST[]>= of(this.designationList);

   workSheetTables: WorkSheetTableConfig[]=[];
   showCloseIcon: boolean=false;
  ngOnInit(): void {
   
    this.getEntity();
    this.onload(); 
    this.getLatestVersion();
  }
  onload(){
    this.searchServ.getPreambleList().subscribe(preambleData=>{
      this.dataSource_Preamble= new MatTableDataSource<Preamble>(preambleData);
      this.searchServ.getReadingThisDocumentList().subscribe(readingData=>{
        this.dataSource_Reading= new MatTableDataSource<ReadingThisDocument>(readingData);
        console.log(this.dataSource_Reading.filteredData);
        this.searchServ.getNoteList().subscribe(note=>{
          this.dataSource_Note= new MatTableDataSource<NoteList>(note);
          console.log(this.dataSource_Note.filteredData);
  
        });
      });
    });
  }
  getLatestVersion(){
    this.searchServ.getCurrentVersion().subscribe((data:any)=>{
      this.latestVersion=data.historyCode;
    });
  }
  getEntity(){
    this.searchServ.getEntityList().subscribe((data:any)=>{
      this.entityList= data;
      let entityID: number;
      if (this.entityList && this.entityList.length > 0) {
        this.entityList.forEach((e) => {
           
          if(e.code == 'IN01'){
            entityID= e.id;
            if (this.selectedEntityValue.indexOf(e) == -1) {
              this.selectedEntityValue.push(e);
            }
          }  
        });
      }
      this.selectEntityFormControl.patchValue(this.selectedEntityValue[0]);
      this.searchServ.getBuisnessAreaList(entityID).subscribe((data:any)=>{
        this.businessList = data,
       
      this.businessAreaFilteredOptions = this.searchBusinessAreaTextboxControl.valueChanges
      .pipe(
        startWith<string>(''),
        map(name => this.businessAreaFilter(name))
      );
         () => console.log('Get all complete'); 
      });
    });
  }
  entityselection(entityID: number){
    this.reset();

    this.searchServ.getBuisnessAreaList(entityID).subscribe((data:any)=>{
      this.businessList = data,
     
    this.businessAreaFilteredOptions = this.searchBusinessAreaTextboxControl.valueChanges
    .pipe(
      startWith<string>(''),
      map(name => this.businessAreaFilter(name))
    );
       () => console.log('Get all complete'); 
    });
  }
  
  private businessAreaFilter(name: string) {
    const filterValue = name.toLowerCase();
    // Set selected values to retain the selected checkbox state 
    this.setBusinessAreaSelectedValues();
    this.selectedBusinessAreaId= new FormControl([]);
    this.selectedBusinessAreaValue= new FormControl([]);
    this.selectBusinessAreaFormControl.patchValue(this.selectedBusinessAreaValues);
    this.selectBusinessAreaFormControl.value.forEach(item=> {
      this.selectedBusinessAreaId.value.push(item.id);
      this.selectedBusinessAreaValue.value.push(item.name);
    });
    let filteredList = this.businessList.filter(option => option.name.toLowerCase().includes(filterValue.toLowerCase()));
    return filteredList;
  }

  businessAreaSelectionChange(event) {
    if (event.isUserInput && event.source.selected == false) {
      let index = this.selectedBusinessAreaValues.indexOf(event.source.value);
      this.selectedBusinessAreaValues.splice(index, 1)
    }
  }
  openedBusinessAreaChange(e) {
    // Set search textbox value as empty while opening selectbox 
    this.searchBusinessAreaTextboxControl.patchValue('');
    // Focus to search textbox while clicking on selectbox
    if (e == true) {
      this.searchBusinessAreaTextBox.nativeElement.focus();
    }
  }

  clearBusinessAreaSearch(event) {
    event.stopPropagation();
    this.searchBusinessAreaTextboxControl.patchValue('');
  }

  setBusinessAreaSelectedValues() {
    console.log('selectBusinessAreaFormControl', this.selectBusinessAreaFormControl.value);
    if (this.selectBusinessAreaFormControl.value && this.selectBusinessAreaFormControl.value.length > 0) {
      this.selectBusinessAreaFormControl.value.forEach((e) => {
        if (this.selectedBusinessAreaValues.indexOf(e) == -1) {
          this.selectedBusinessAreaValues.push(e);
        }
      });
    }
  }
  businessAreaSelection() {
    
    this.allSelected=false;
    this.divisionList=[];
    this.designationList=[];
    this.dataSource1 = new MatTableDataSource<EmployeeDetails>([]);
    
    this.selectedDivisionId= new FormControl([]);
    this.selectedDivisionValue= new FormControl([]);
  
    this.selectedDesignationId= new FormControl([]);
    this.selectedDesignationValue= new FormControl([]);

    this.selectedBusinessAreaId= new FormControl([]);
    this.selectedBusinessAreaValue= new FormControl([]);

    this.selectDivision.options.forEach((data: MatOption) => data.deselect());
    this.selectDesignation.options.forEach((data: MatOption) => data.deselect());
    this.allSelectedDivision= false;
    this.allSelectedDesignation = false;
    this.getSelectedItemsList   = "Select Activity!";
    this.checklistSelection.clear();
   if(this.selectedBusinessAreaValues.length !=0){
    this.selectedBusinessAreaValues.forEach(item=> {
      this.selectedBusinessAreaId.value.push(item.id);
      this.selectedBusinessAreaValue.value.push(item.name);
    });
   }
    this.selectBusinessAreaFormControl.value.forEach(item=> {
      if(!this.selectedBusinessAreaIdExists(item.id)){
        this.selectedBusinessAreaId.value.push(item.id);
      }
      if(!this.selectedBusinessAreaValueExists(item.name)){
        this.selectedBusinessAreaValue.value.push(item.name);
      }
    });
    this.formInstance.value.businessControl = this.selectedBusinessAreaId.value;
    console.log(this.formInstance.value.businessControl);
    let newStatus = true;
      if(this.formInstance.value.businessControl.length > 1){
      console.log('len',this.formInstance.value.businessControl.length);
      this.isMultipleSelected = true;
      } 
      else{
        this.isMultipleSelected = false;
      }   
      this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
       
      }
      if(this.formInstance.value.businessControl.length == this.businessList.length){
        this.allSelected = true;
      }else{
        this.allSelected = false;
      }
    });
   
    this.searchServ.getSearchActivityList(this.formInstance.value.businessControl.join(",")).subscribe((data1: any[])=>{
      console.log(data1);
      const data = this.treeConstruct(data1);

      this.dataSource_Tree.data = data;
      this.treeControl.dataNodes = data;
      Object.keys(this.dataSource_Tree.data).forEach(x => {
       this.setParent(this.dataSource_Tree.data[x], null);
     });

    this.dataChange.next(data);
    });  
    this.searchServ.getDivisionListFromBusinessAreaID(this.formInstance.value.businessControl.join(",")).subscribe((data)=>{
      console.log(data);
       this.divisionList= data,
       this.divisionFilteredOptions = this.searchDivisionTextboxControl.valueChanges
       .pipe(
         startWith<string>(''),
         map(name => this.divisionFilter(name))
       );
       () => console.log('Get all complete'); 
    });
    
    this.searchServ.getDesignationListFromBusinessAreaIDandDivisionID(this.formInstance.value.businessControl,this.formInstance.value.divisionControl).subscribe((data)=>{
      console.log(data);
       this.designationList= data,
       this.designationFilteredOptions = this.searchDesignationTextboxControl.valueChanges
       .pipe(
         startWith<string>(''),
         map(name => this.designationFilter(name))
       );
       () => console.log('Get all complete'); 
    });

   // this.allSelected = newStatus;
   // this.allSelectedDivision= newStatusDivision;
   // this.allSelectedDesignation = newStatusDesignation;
  }
  
  selectedBusinessAreaIdExists(id: number):boolean{
    return this.selectedBusinessAreaId.value.some(item=>item === id);
  }
  selectedBusinessAreaValueExists(value: string):boolean{
    return this.selectedBusinessAreaValue.value.some(item=>item === value);
  }
  toggleAllSelection() {
    this.divisionList=[];
    this.designationList=[];
    //this.divisionFilteredOptions=[];
    //this.designationFilteredOptions=[];

    this.selectedBusinessAreaId= new FormControl([]);
    this.selectedBusinessAreaValue= new FormControl([]);

    this.selectDivision.options.forEach((data: MatOption) => data.deselect());
    this.selectDesignation.options.forEach((data: MatOption) => data.deselect());
    this.allSelectedDivision= false;
    this.allSelectedDesignation = false;
    this.getSelectedItemsList   = "Select Activity!";
    this.checklistSelection.clear();

    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
      this.isMultipleSelected = true;
      this.selectedBusinessAreaId= new FormControl([]);
      this.selectedBusinessAreaValue= new FormControl([]);

      this.selectBusinessAreaFormControl.value.forEach(item=> {
        this.selectedBusinessAreaId.value.push(item.id);
        this.selectedBusinessAreaValue.value.push(item.name);
      });
      this.formInstance.value.businessControl = this.selectedBusinessAreaId.value;
      console.log(this.formInstance.value.businessControl);

    this.searchServ.getSearchActivityList(this.formInstance.value.businessControl.join(",")).subscribe((data1: any[])=>{
      console.log(data1);
      const data = this.treeConstruct(data1);

      this.dataSource_Tree.data = data;
      this.treeControl.dataNodes = data;
      Object.keys(this.dataSource_Tree.data).forEach(x => {
       this.setParent(this.dataSource_Tree.data[x], null);
     });

    this.dataChange.next(data);
    });  


    this.searchServ.getDivisionListFromBusinessAreaID(this.formInstance.value.businessControl.join(",")).subscribe((data)=>{
      console.log(data);
      this.formInstance.controls.divisionControl.enable() ;
       this.divisionList= data,
       this.divisionFilteredOptions = this.searchDivisionTextboxControl.valueChanges
       .pipe(
         startWith<string>(''),
         map(name => this.divisionFilter(name))
       );  
       () => console.log('Get all complete'); 
    });
    
    this.searchServ.getDesignationListFromBusinessAreaIDandDivisionID(this.formInstance.value.businessControl,this.formInstance.value.divisionControl).subscribe((data)=>{
      console.log(data);
      this.formInstance.controls.designationControl.enable() ;
       this.designationList= data,
       this.designationFilteredOptions = this.searchDesignationTextboxControl.valueChanges
       .pipe(
         startWith<string>(''),
         map(name => this.designationFilter(name))
       ); 
       () => console.log('Get all complete'); 
    });
      
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect()); 
       this.isMultipleSelected = false;
       
       this.selectedBusinessAreaId= new FormControl([]);
       this.selectedBusinessAreaValue= new FormControl([]);
       this.selectBusinessAreaFormControl = new FormControl([]);

    }

    
  }

  private divisionFilter(name: string) {
    const filterValue = name.toLowerCase();
    // Set selected values to retain the selected checkbox state 
    this.setDivisionSelectedValues();
    this.selectedDivisionId= new FormControl([]);
    this.selectedDivisionValue= new FormControl([]);
    this.selectDivisionFormControl.patchValue(this.selectedDivisionValues);
    this.selectDivisionFormControl.value.forEach(item=> {
      this.selectedDivisionId.value.push(item.id);
      this.selectedDivisionValue.value.push(item.name);
    });
    let filteredList = this.divisionList.filter(option => option.name.toLowerCase().includes(filterValue.toLowerCase()));
    return filteredList;
  }

  divisionSelectionChange(event) {
    if (event.isUserInput && event.source.selected == false) {
      let index = this.selectedDivisionValues.indexOf(event.source.value);
      this.selectedDivisionValues.splice(index, 1)
    }
  }
  openedDivisionChange(e) {
    // Set search textbox value as empty while opening selectbox 
    this.searchDivisionTextboxControl.patchValue('');
    // Focus to search textbox while clicking on selectbox
    if (e == true) {
      this.searchDivisionTextBox.nativeElement.focus();
    }
  }

  clearDivisionSearch(event) {
    event.stopPropagation();
    this.searchDivisionTextboxControl.patchValue('');
  }

  setDivisionSelectedValues() {
    console.log('selectDivisionFormControl', this.selectDivisionFormControl.value);
    if (this.selectDivisionFormControl.value && this.selectDivisionFormControl.value.length > 0) {
      this.selectDivisionFormControl.value.forEach((e) => {
        if (this.selectedDivisionValues.indexOf(e) == -1) {
          this.selectedDivisionValues.push(e);
        }
      });
    }
  }
  selectedDivisionIdExists(id: number):boolean{
    return this.selectedDivisionId.value.some(item=>item === id);
  }
  selectedDivisionValueExists(value: string):boolean{
    return this.selectedDivisionValue.value.some(item=>item === value);
  }
  divisionSelection(){
    this.selectedDivisionId= new FormControl([]);
    this.selectedDivisionValue= new FormControl([]);
    if(this.selectedDivisionValues.length !=0){
      this.selectedDivisionValues.forEach(item=> {
        this.selectedDivisionId.value.push(item.id);
        this.selectedDivisionValue.value.push(item.name);
      });
     }
    this.selectDivisionFormControl.value.forEach(item=> {
      if(!this.selectedDivisionIdExists(item.id)){
        this.selectedDivisionId.value.push(item.id);
      }
      if(!this.selectedDivisionValueExists(item.name)){
        this.selectedDivisionValue.value.push(item.name);
      }
    });
    
    this.formInstance.value.divisionControl= this.selectedDivisionId.value;
    console.log(this.formInstance.value.businessControl);
  
    let newStatus = true;
    if(this.formInstance.value.divisionControl.length > 1){
      console.log('len',this.formInstance.value.divisionControl.length);
      this.isMultipleSelected = true;
      } 
      else{
        this.isMultipleSelected = false;
      }   
      this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
       
      }
      if(this.formInstance.value.divisionControl.length == this.divisionList.length){
        this.allSelectedDivision = true;
      }else{
        this.allSelectedDivision = false;
      }
    });
    console.log(this.formInstance.value.businessControl);
    console.log(this.formInstance.value.divisionControl);
/*     this.searchServ.getDesignationListFromBusinessAreaIDandDivisionID(this.formInstance.value.businessControl,this.formInstance.value.divisionControl).subscribe((data)=>{
      console.log(data);
       this.designationList= data,
       error => console.log(error),  
       () => console.log('Get all complete'); 
    }); */
    //this.allSelected = newStatus;
   // this.allSelectedDivision= newStatus;
  }
  toggleAllSelectionDivision() {
    if (this.allSelectedDivision) {
      this.selectDivision.options.forEach((item: MatOption) => item.select());
      this.isMultipleSelectedDivision = true;
      this.selectedDivisionId= new FormControl([]);
       this.selectedDivisionValue= new FormControl([]);
       this.selectDivisionFormControl.value.forEach(item=> {
        this.selectedDivisionId.value.push(item.id);
        this.selectedDivisionValue.value.push(item.name);
      });
      
      this.formInstance.value.divisionControl= this.selectedDivisionId.value;
    } else {
      this.selectDivision.options.forEach((item: MatOption) => item.deselect()); 
       this.isMultipleSelectedDivision = false;
       this.selectedDivisionId= new FormControl([]);
       this.selectedDivisionValue= new FormControl([]);
       this.selectDivisionFormControl = new FormControl([]);
    }
  }
  selectionDivisionChange(event, divisionName:string){
    if(event.source.selected == false){
      const deselectedValue= event.source.value;
      const currentId= this.selectedDivisionId.value;
      const filteredId= currentId.filter(item=>item !== deselectedValue);
      this.selectedDivisionId.setValue(filteredId);

      const currentValues= this.selectedDivisionValue.value;
      const filteredValues= currentValues.filter(item=>item !== divisionName);
      this.selectedDivisionValue.setValue(filteredValues);
    }
  }
  
  private designationFilter(name: string) {
    const filterValue = name.toLowerCase();
    // Set selected values to retain the selected checkbox state 
    this.setDesignationSelectedValues();
    this.selectedDesignationId= new FormControl([]);
    this.selectedDesignationValue= new FormControl([]);
    this.selectDesignationFormControl.patchValue(this.selectedDesignationValues);
    this.selectDesignationFormControl.value.forEach(item=> {
      this.selectedDesignationId.value.push(item.id);
      this.selectedDesignationValue.value.push(item.name);
    });
    let filteredList = this.designationList.filter(option => option.name.toLowerCase().includes(filterValue.toLowerCase()));
    return filteredList;
  }

  designationSelectionChange(event) {
    if (event.isUserInput && event.source.selected == false) {
      let index = this.selectedDesignationValues.indexOf(event.source.value);
      this.selectedDesignationValues.splice(index, 1)
    }
  }
  openedDesignationChange(e) {
    // Set search textbox value as empty while opening selectbox 
    this.searchDesignationTextboxControl.patchValue('');
    // Focus to search textbox while clicking on selectbox
    if (e == true) {
      this.searchDivisionTextBox.nativeElement.focus();
    }
  }

  clearDesignationSearch(event) {
    event.stopPropagation();
    this.searchDesignationTextboxControl.patchValue('');
  }

  setDesignationSelectedValues() {
    console.log('selectDesignationFormControl', this.selectDesignationFormControl.value);
    if (this.selectDesignationFormControl.value && this.selectDesignationFormControl.value.length > 0) {
      this.selectDesignationFormControl.value.forEach((e) => {
        if (this.selectedDesignationValues.indexOf(e) == -1) {
          this.selectedDesignationValues.push(e);
        }
      });
    }
  }

  selectedDesignationIdExists(id: number):boolean{
    return this.selectedDesignationId.value.some(item=>item === id);
  }
  selectedDesignationValueExists(value: string):boolean{
    return this.selectedDesignationValue.value.some(item=>item === value);
  }
  toggleAllSelectionDesignation() {
    if (this.allSelectedDesignation) {
      this.selectDesignation.options.forEach((item: MatOption) => item.select());
      this.isMultipleSelectedDesignation = true;
      this.selectedDesignationValue= new FormControl([]);
       this.selectedDesignationValue= new FormControl([]);
      
       this.selectDesignationFormControl.value.forEach(item=> {
        this.selectedDesignationId.value.push(item.id);
        this.selectedDesignationValue.value.push(item.name);
      });
    
    this.formInstance.value.selectedControl= this.selectedDesignationId.value;
    } else {
       this.selectDesignation.options.forEach((item: MatOption) => item.deselect()); 
       this.isMultipleSelectedDesignation = false;
       this.selectedDesignationId= new FormControl([]);
       this.selectedDesignationValue= new FormControl([]);
    }
  }
  designationSelection(){
    this.selectedDesignationId= new FormControl([]);
    this.selectedDesignationValue= new FormControl([]);
    if(this.selectedDesignationValues.length !=0){
      this.selectedDesignationValues.forEach(item=> {
        this.selectedDesignationId.value.push(item.id);
        this.selectedDesignationValue.value.push(item.name);
      });
     }
    this.selectDesignationFormControl.value.forEach(item=> {
      if(!this.selectedDesignationIdExists(item.id)){
        this.selectedDesignationId.value.push(item.id);
      }
      if(!this.selectedDesignationValueExists(item.name)){
        this.selectedDesignationValue.value.push(item.name);
      }
     
    });

    this.formInstance.value.selectedControl= this.selectedDesignationId.value;
    if(this.formInstance.value.selectedControl.length == this.designationList.length){
      this.allSelectedDesignation = true;
    }else{
      this.allSelectedDesignation = false;
    }
  }
handleInput(event: KeyboardEvent): void{
   event.stopPropagation();
} 
/*********************************  Treeview   methods  Start********************************* **/

  treeConstruct(treeData) {
    let constructedTree = [];
    for (let i of treeData) {
      let treeObj = i;
      let assigned = false;
      this.constructTree(constructedTree, treeObj, assigned)
    }
    return constructedTree;
  }
constructTree(constructedTree, treeObj, assigned) {
if (treeObj.parentId == null) {
      treeObj.children = [];
      constructedTree.push(treeObj);
      return true;
    } else if (treeObj.parentId == constructedTree.id) {
      treeObj.children = [];
      constructedTree.children.push(treeObj);
      return true;
    }
    else {
      if (constructedTree.children != undefined) {
        for (let index = 0; index < constructedTree.children.length; index++) {
          let constructedObj = constructedTree.children[index];
          if (assigned == false) {
            assigned = this.constructTree(constructedObj, treeObj, assigned);
          }
        }
      } else {
        for (let index = 0; index < constructedTree.length; index++) {
          let constructedObj = constructedTree[index];
          if (assigned == false) {
            assigned = this.constructTree(constructedObj, treeObj, assigned);
          }
        }
      }
      return false;
    }
  }


  nestedNodeMap = new Map<TodoItemNode, TodoItemFlatNode>(); 
  treeControl: NestedTreeControl<TodoItemFlatNode>; 
  dataSource_Tree:  MatTreeNestedDataSource<TodoItemNode>; 
  checklistSelection = new SelectionModel<TodoItemNode>(true /* multiple */);

  constructor(private sanitizer : DomSanitizer,private _database: ChecklistDatabase,private _formBuilder: FormBuilder,private searchServ: SearchService) {
  
    this.treeControl = new NestedTreeControl<TodoItemNode>(node => node.children);
    this.dataSource_Tree = new MatTreeNestedDataSource<TodoItemNode>();

    this.formInstance = _formBuilder.group({
     "businessControl":['', Validators.required],
      "divisionControl":[''],
     "designationControl":[''], 

   });


    /*  _database.dataChange.subscribe(data => {
      this.dataSource_Tree.data = data;

      Object.keys(this.dataSource_Tree.data).forEach(x => {
       this.setParent(this.dataSource_Tree.data[x], null);
     });
    });  */
  }

  getLevel = (node: TodoItemFlatNode) => node.level;
  isExpandable = (node: TodoItemFlatNode) => node.expandable;
  getChildren = (node: TodoItemNode): TodoItemNode[] => node.children;
  hasNoContent = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.text === '';
  hasChild = (_: number, node: TodoItemNode) => !!node.children && node.children.length > 0;
  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */

  
setParent(data, parent) {
  data.parent = parent;
  if (data.children) {
    data.children.forEach(x => {
      this.setParent(x, data);
    });
  }
}

  checkAllParents(node) {
   if (node.parent) {
     const descendants = this.treeControl.getDescendants(node.parent);
     node.parent.selected = descendants.every(child => child.selected);
     node.parent.indeterminate = descendants.some(child => child.selected);
     this.checkAllParents(node.parent);
   }
 }

/*   descendantsAllSelected(node: TodoItemFlatNode): boolean {
   const descendants = this.treeControl.getDescendants(node);
   
   
   return descendants.every(child => this.checklistSelection.isSelected(child));

 } */

 descendantsAllSelected(node: TodoItemFlatNode): boolean {
   const descendants = this.treeControl.getDescendants(node);
   let allselected = descendants.every(child => this.checklistSelection.isSelected(child));
   if (allselected)
     this.checklistSelection.select(node);
   else
     this.checklistSelection.deselect(node);
   return allselected;
 }


 /** Whether part of the descendants are selected */
 descendantsPartiallySelected(node: TodoItemFlatNode): boolean {
   const descendants = this.treeControl.getDescendants(node);
   const result = descendants.some(child => this.checklistSelection.isSelected(child));
   return result && !this.descendantsAllSelected(node);
 }



 



 todoItemSelectionToggle(checked, node) {
  
   this.checklistSelection.toggle(node);
   const descendants = this.treeControl.getDescendants(node);
   this.checklistSelection.isSelected(node)
     ? this.checklistSelection.select(node)
     : this.checklistSelection.deselect(node);

   // Force update for the parent
   descendants.forEach(child => this.checklistSelection.isSelected(node));
   this.checkAllParents(node);
  

   node.selected = checked;
   if (node.children) {
     node.children.forEach(x => {
       this.todoItemSelectionToggle(checked, x);
       
     });
   }
   this.checkAllParents(node);


   

  


  /*  console.log(result);

 if (!this.checklistSelection.selected.length) 
     this.getSelectedItemsList   = "Select Activity!";
     else
     this.getSelectedItemsList   = result.join(','); */ 
    
     if (!this.checklistSelection.selected.length)
      {
      this.getSelectedItemsList   =  "Select Activity";
      this.showCloseIcon=false;
     }
      
     else
     {        
       this.getSelectedItemsList   = this.checklistSelection.selected.map(s => s.text).join(",");
       this.showCloseIcon= true;
       this.searchValueFormControl.setValue('');
     }


 }

 filterLeafNode(node: TodoItemFlatNode): boolean {
   if (!this.searchString) {
     return false
   }
   return node.text.toLowerCase()
     .indexOf(this.searchString?.toLowerCase()) === -1
   
 }


// filter recursively on a text string using property object value
filterRecursive(filterText: string, array: any[], property: string) {
 let filteredData;

 //make a copy of the data so we don't mutate the original
 function copy(o: any) {
   return Object.assign({}, o);
 }

 // has string
 if (filterText) {
   // need the string to match the property value
   filterText = filterText.toLowerCase();
   // copy obj so we don't mutate it and filter
   filteredData = array.map(copy).filter(function x(y) {
     if (y[property].toLowerCase().includes(filterText)) {
       return true;
     }
     // if children match
     if (y.children) {
       return (y.children = y.children.map(copy).filter(x)).length;
     }
   });
   // no string, return whole array
 } else {
   filteredData = array;
 }

 return filteredData;
}

  // pass mat input string to recursive function and return data
  filterTree(filterText: string) {
   // use filter input text, return filtered TREE_DATA, use the 'name' object value
   this.dataSource_Tree.data = this.filterRecursive(filterText, this.data, 'text');
   this.treeControl.dataNodes = this.data;
 }

 // filter string from mat input filter
 applyFilterForTree(event: Event) {
   const filterText = (event.target as HTMLInputElement).value;
   this.filterTree(filterText);
   // show / hide based on state of filter string
   if (filterText) {
    this.tree.treeControl.collapseAll();
   } else {
    
    this.tree.treeControl.expandAll();
   }
 }

/*********************************  Treeview   methods  End ********************************* **/

  
  createWorksheetWithColumnColors(data:any[][]){
    const worksheet: XLSX.WorkSheet= XLSX.utils.aoa_to_sheet(data);
    const range= XLSX.utils.decode_range(worksheet['!ref']);
    for (let rowIndex= range.s.r; rowIndex <= range.e.r;rowIndex++){
      const cellAddress= XLSX.utils.encode_cell({r:rowIndex,c: 15});
      worksheet[cellAddress].s={fill:{bgColor: {rgb: rowIndex[15] }}};
    }
  }
  generatePDF(){
    const currentDateTime= new Date();
    const dateString= currentDateTime.toISOString().replace(/[:.]/g,'-');
    const dataToSend=this.dataSource1.filteredData;
    this.searchServ.generatePdf(dataToSend, this.businessList.map(item=>item.id).join(","), this.formInstance.value.businessControl.join(",")).subscribe(response=>{
    const file= new Blob([response],{type:'application/pdf'});
    const url= window.URL.createObjectURL(response);
    const link= document.createElement('a');
    link.href=url;
    link.download=  `${this.latestVersion}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    });
  }

  convertToPlainText(html:string): SafeHtml{
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
  
  generateExcel(){
    const currentDateTime= new Date();
    const dateString= currentDateTime.toISOString().replace(/[:.]/g,'-');
    const tabledata_Preamble= this.dataSource_Preamble.filteredData.map(row=>[row.id, row.topic,row.remarks]);
    const dataPreamble=[
      ["","","Generated on:"+dateString],
      ["Preamble - an introduction to the contents, structure and flow of the TOA"],
      ["","",""],
      ["ID","Column","Description"],
      ...tabledata_Preamble
    ];
    const workSheet_Preamble: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(dataPreamble);
    const header_Style= {
      font:{
        bold: true,
        color:{rgb:"FFFFFF"},
        name: 'ABBvoice',
      },
      alignment: {
        vertical: 'center',
        horizontal: 'center',
        wrapText: '1', // any truthy value here
      },
      fill:{
        fgColor:{rgb:"ff000f"}
      },
      border: {
        right: {
          style: 'thin',
          color: '000000',
        },
        bottom: {
          style: 'thin',
          color: '000000',
        },
        top: {
          style: 'thin',
          color: '000000',
        },
        left: {
          style: 'thin',
          color: '000000',
        },
      },
    }
    const header_Style_Divisions= {
      font:{
        bold: true,
        color:{rgb:"FFFFFF"},
        name: 'ABBvoice',
      },
      alignment: {
        vertical: 'center',
        horizontal: 'center',
        wrapText: '1', // any truthy value here
      },
      fill:{
        fgColor:{rgb:"ff000f"}
      },
      border: {
        right: {
          style: 'thin',
          color: '000000',
        },
        bottom: {
          style: 'thin',
          color: '000000',
        },
        top: {
          style: 'thin',
          color: '000000',
        },
        left: {
          style: 'thin',
          color: '000000',
        },
      },
    }
    workSheet_Preamble['!cols']=[{width:10},{width:30},{width:120}];
    workSheet_Preamble['!rows']=[{hpx:15},{hpx:30},{hpx:30}];
    
    for (var i in workSheet_Preamble) {
      workSheet_Preamble['C1'].s={
        border:{},
        font:{
          bold: true,
          color:{rgb:"000000"},
          name: 'ABBvoice',
        },
        alignment: {
          vertical: 'right',
          horizontal: 'right',
          wrapText: '1', // any truthy value here
        },
      },
      workSheet_Preamble['A2'].s={
        border:{},
        font:{
          bold: true,
          color:{rgb:"000000"},
          name: 'ABBvoice',
          underline:true
        },
      },
      workSheet_Preamble['A1'].s={
        border:{}
      },
      workSheet_Preamble['B1'].s={
        border:{}
      },
      workSheet_Preamble['A3'].s={
        border:{}
      },
      workSheet_Preamble['B3'].s={
        border:{}
      },
      workSheet_Preamble['C3'].s={
        border:{}
      },
      workSheet_Preamble['A4'].s=header_Style;
      workSheet_Preamble['B4'].s=header_Style;
      workSheet_Preamble['C4'].s=header_Style;
      if (typeof workSheet_Preamble[i] != 'object') continue;
      let cell = XLSX.utils.decode_cell(i);
      workSheet_Preamble[i].s = {
        wch: 50,
        // styling for all cells
        font: {
          name: 'ABBvoice',
        },
        alignment: {
          vertical: 'center',
          horizontal: 'center',
          wrapText: '1', // any truthy value here
        },
        border: {
          right: {
            style: 'thin',
            color: '000000',
          },
          bottom: {
            style: 'thin',
            color: '000000',
          },
          top: {
            style: 'thin',
            color: '000000',
          },
          left: {
            style: 'thin',
            color: '000000',
          },
        },
      };

      if (cell.c == 6) {
        // first column
        workSheet_Preamble[i].s.numFmt = 'DD-MM-YYYY'; // for dates
        workSheet_Preamble[i].z = 'DD-MM-YYYY';
      } else {
        workSheet_Preamble[i].s.numFmt = '00'; // other numbers
      }

      if (cell.r == 0) {
        // first row
        workSheet_Preamble[i].s.border.bottom = {
          // bottom border
          style: 'thin',
          color: '000000',
        };
      }
    }

    const combined_wb: XLSX.WorkBook= XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(combined_wb, workSheet_Preamble, 'Preamble');


    const tabledata_Reading= this.dataSource_Reading.filteredData.map(row=>[row.id, row.column,row.description]);
    console.log(tabledata_Reading);
    const dataReading=[
      ["Reading this document"],
      ["Listed below is the description of the column headers in the TOA sheet. It guides the users to interpret the columns and how the information therein is populated."],
      ["","",""],
      ["ID","Column","Description"],
      ...tabledata_Reading
    ];
    const workSheet_Reading: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(dataReading);
    workSheet_Reading['!cols']=[{width:10},{width:30},{width:120}];
    workSheet_Reading['!rows']=[{hpx:15},{hpx:30},{hpx:30}];
    
    for (var i in workSheet_Reading) {
      workSheet_Reading['A1'].s={
        border:{},
        font:{
          bold: true,
          color:{rgb:"000000"},
          name: 'ABBvoice',
          underline:true
        },
      },
      workSheet_Reading['A2'].s={
        border:{},
        font:{
          bold: true,
          color:{rgb:"000000"},
          name: 'ABBvoice',
          underline:true
        },
      },
      workSheet_Reading['A3'].s={
        border:{}
      },
      workSheet_Reading['B3'].s={
        border:{}
      },
      workSheet_Reading['C3'].s={
        border:{}
      },
      workSheet_Reading['A4'].s=header_Style;
      workSheet_Reading['B4'].s=header_Style;
      workSheet_Reading['C4'].s=header_Style;
      if (typeof workSheet_Reading[i] != 'object') continue;
      let cell = XLSX.utils.decode_cell(i);
      workSheet_Reading[i].s = {
        wch: 50,
        // styling for all cells
        font: {
          name: 'ABBvoice',
        },
        alignment: {
          vertical: 'center',
          horizontal: 'center',
          wrapText: '1', // any truthy value here
        },
        border: {
          right: {
            style: 'thin',
            color: '000000',
          },
          bottom: {
            style: 'thin',
            color: '000000',
          },
          top: {
            style: 'thin',
            color: '000000',
          },
          left: {
            style: 'thin',
            color: '000000',
          },
        },
      };

      if (cell.c == 6) {
        // first column
        workSheet_Reading[i].s.numFmt = 'DD-MM-YYYY'; // for dates
        workSheet_Reading[i].z = 'DD-MM-YYYY';
      } else {
        workSheet_Reading[i].s.numFmt = '00'; // other numbers
      }

      if (cell.r == 0) {
        // first row
        workSheet_Reading[i].s.border.bottom = {
          // bottom border
          style: 'thin',
          color: '000000',
        };
      }
    }

    XLSX.utils.book_append_sheet(combined_wb, workSheet_Reading, 'Reading this document');
    for(let w = 0; w < this.workSheetTables.length; w++) 
      {
      const table = document.getElementById(this.workSheetTables[w].tableId);
      const workSheet_CommonData: XLSX.WorkSheet= XLSX.utils.table_to_sheet(table);
      workSheet_CommonData['!cols']=[{width:30},{width:40},{width:40},{width:40},{width:40},{width:40},{width:60},{width:40},{width:40},{width:40},{width:40},{width:60},{width:40},{width:40},{width:40},{width:40}];
      workSheet_CommonData['!rows']=[{hpx:15},{hpx:30},{hpx:30},{hpx:30},{hpx:30},{hpx:30},{hpx:30},{hpx:30},{hpx:30},{hpx:30},{hpx:30},{hpx:30},{hpx:30},{hpx:30},{hpx:30},{hpx:30},{hpx:30}];
      
      // Get the rows and cells from the HTML table
    const rows = table.querySelectorAll('tr');
    rows.forEach((row, rowIndex) => {
      const cells = row.querySelectorAll('td, th');
      cells.forEach((cell, colIndex) => {
        const cellAddress = XLSX.utils.encode_cell({ r: rowIndex, c: colIndex });
        // Assign cell value from HTML table
        const cellValue = cell.textContent?.trim() || '';
 
        // Update the worksheet cell

        if(workSheet_CommonData[cellAddress].l == undefined){
          workSheet_CommonData[cellAddress] = {
            v: cellValue,
            t: 's', // Set cell type to string
            s: { numFmt: '@' } // Ensure cell is treated as text in Excel
          };
        }
        else{
          workSheet_CommonData[cellAddress] = {
            v: cellValue,
            t: 's', // Set cell type to string
            s: { numFmt: '@' } ,// Ensure cell is treated as text in Excel
            l: workSheet_CommonData[cellAddress].l
          };
        }
      });
    });
    for (var i in workSheet_CommonData) {

      workSheet_CommonData['A1'].s= header_Style_Divisions;
      workSheet_CommonData['B1'].s= header_Style_Divisions
      workSheet_CommonData['C1'].s=header_Style_Divisions;
      workSheet_CommonData['D1'].s=header_Style_Divisions
      workSheet_CommonData['E1'].s=header_Style_Divisions
      workSheet_CommonData['F1'].s=header_Style_Divisions
      workSheet_CommonData['G1'].s=header_Style_Divisions
      workSheet_CommonData['H1'].s=header_Style_Divisions
      workSheet_CommonData['I1'].s=header_Style_Divisions
      workSheet_CommonData['J1'].s=header_Style_Divisions
      workSheet_CommonData['K1'].s=header_Style_Divisions
      workSheet_CommonData['L1'].s=header_Style_Divisions
      workSheet_CommonData['M1'].s=header_Style_Divisions
      workSheet_CommonData['N1'].s=header_Style_Divisions
      workSheet_CommonData['O1'].s=header_Style_Divisions
     

      if (typeof workSheet_CommonData[i] != 'object') continue;
      
      let cell = XLSX.utils.decode_cell(i);
      workSheet_CommonData[i].s = {
        wch: 50,
        // styling for all cells
        font: {
          name: 'ABBvoice',
        },
        alignment: {
          vertical: 'center',
          horizontal: 'center',
          wrapText: '1', // any truthy value here
        },
        border: {
          right: {
            style: 'thin',
            color: '000000',
          },
          bottom: {
            style: 'thin',
            color: '000000',
          },
          top: {
            style: 'thin',
            color: '000000',
          },
          left: {
            style: 'thin',
            color: '000000',
          },
        },
      };
      
      if (cell.c == 6) {
        // first column
        workSheet_CommonData[i].s.numFmt = 'DD-MM-YYYY'; // for dates
        workSheet_CommonData[i].z = 'DD-MM-YYYY';
      } else {
        workSheet_CommonData[i].s.numFmt = '00'; // other numbers
      }

      if (cell.r == 0) {
        // first row
        workSheet_CommonData[i].s.border.bottom = {
          // bottom border
          style: 'thin',
          color: '000000',
        };
      }
    }
   this.applyBgColorFontColorFontweightForColumns(workSheet_CommonData, this.workSheetTables[w].dataSource.data);
      workSheet_CommonData['M1'].s=header_Style_Divisions;
      workSheet_CommonData['N1'].s=header_Style_Divisions;
      workSheet_CommonData['O1'].s=header_Style_Divisions;
      
      if(this.workSheetTables[w].dataSource.data.length == 0){
        XLSX.utils.sheet_add_aoa(workSheet_CommonData,[['No records found']], {origin : 'A2'});
        workSheet_CommonData['A2'].s= {
          font:{
            bold: true,
            color:{rgb:"000000"},
            name: 'ABBvoice',
          },
          alignment: {
            vertical: 'center',
            horizontal: 'center',
            wrapText: '1', // any truthy value here
          },
        }
      }
      var buinessNotes= this.dataSource_Note.filteredData.find(row=>row.businessUnitCode===this.workSheetTables[w].tableId);
      let appendNodes='';
      if(buinessNotes!=undefined){
        appendNodes=buinessNotes.note;
      }
      const range= XLSX.utils.decode_range(workSheet_CommonData['!ref']!);
      const rowCount= range.e.r-range.s.r+1;
      const setNote=rowCount+1;
      XLSX.utils.sheet_add_aoa(workSheet_CommonData,[[appendNodes]], {origin : setNote});
      const cellAddrss= XLSX.utils.encode_cell({r:setNote,c:0})
      workSheet_CommonData[cellAddrss].s= {
        font:{
          bold: false,
          color:{rgb:"000000"},
          name: 'ABBvoice',
        },
       
      }
      
    XLSX.utils.book_append_sheet(combined_wb, workSheet_CommonData, this.workSheetTables[w].tableId);
      }
    XLSX.writeFile(combined_wb, `${this.latestVersion}.xlsx`);
  
  }
  
   applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource1.filter = filterValue.trim().toLowerCase();
    this.dummyDataSource.filter = filterValue.trim().toLowerCase();
    
    this.workSheetTables=[];
    for(let i = 0; i < this.businessList.length; i++)
      {
        if(this.formInstance.value.businessControl.includes(this.businessList[i].id)){
          this.workSheetTables.push({
            displayedColumns: ['BgColor','FontColor','FontWeight','SourceOfActivity','CatBatReference','CPReference','MIReference','BRReference','Tool','ApplicableToBusiness','Activity','Responsible', 'Accountable' , 'Consult', 'Inform','Remark','ActivityFormingPartOfLat','BuisnessArea','BusinessAreaCode'],
            dataSource: new MatTableDataSource(this.dataSource1.filteredData.filter(row=>row.businessAreaCode===this.businessList[i].code)),
            tableId: this.businessList[i].code
          });
        }
      }
      
    console.log(this.workSheetTables);
    if (this.dataSource1.paginator) {
      this.dataSource1.paginator.firstPage();
    }

  }

  
  Search(){
    console.log('val ',this.formInstance.value)
    if(this.formInstance.value.businessControl.length>1){
      this.isMultipleSelected = false;
      console.log('true')
    }


     let result = [];
    this.dataSource_Tree.data.forEach(node => {
      result = result.concat(
        this.treeControl
          .getDescendants(node)
          .filter(x => x.selected && x.id)
          .map(x => x.id)
      );
    }); 
   if(this.selectedBusinessAreaId.value==null)
    return;
  
    this.formInstance.value.businessControl= this.selectedBusinessAreaId.value;
    this.formInstance.value.divisionControl= this.selectedDivisionId.value;
    this.formInstance.value.designationControl= this.selectedDesignationId.value;    var busIDs="0"
    var divIDs="0"
    var designationIDs="0"
    var activityIDs="0"
  if(this.formInstance.value.businessControl!="")
 busIDs=this.formInstance.value.businessControl.join(",")
  if(this.formInstance.value.divisionControl!="")
    divIDs=this.formInstance.value.divisionControl.join(",")
  if(this.formInstance.value.designationControl!="")
    designationIDs=this.formInstance.value.designationControl.join(",")
  if(this.checklistSelection.selected.map(s => s.id).join(",") !="")
    activityIDs=this.checklistSelection.selected.map(s => s.id).join(",") 

    const myData = { busIDs: busIDs, 
      divIDs: divIDs, 
      designationIDs: designationIDs, 
      activityIDs: activityIDs };
      this.searchServ.getSearchActivityDetails(myData).subscribe((data1: any[])=>{
      
      this.dataSource1= new MatTableDataSource<EmployeeDetails>(data1);
      this.dummyDataSource= new MatTableDataSource<EmployeeDetails>(data1);
      console.log(data1);
     
      this.workSheetTables=[];
      for(let i = 0; i < this.businessList.length; i++)
      {
        if(this.formInstance.value.businessControl.includes(this.businessList[i].id)){
          this.workSheetTables.push({
            displayedColumns: ['BgColor','FontColor','FontWeight','SourceOfActivity','CatBatReference','CPReference','MIReference','BRReference','Tool','ApplicableToBusiness','Activity','Responsible', 'Accountable' , 'Consult', 'Inform','Remark','ActivityFormingPartOfLat','BuisnessArea','BusinessAreaCode'],
            dataSource: new MatTableDataSource(data1.filter(row=>row.businessAreaCode===this.businessList[i].code)),
            tableId: this.businessList[i].code
          });
        }
      }

      console.log(this.workSheetTables);
      this.dataSource1.paginator = this.paginator;
      this.dataSource1.sort = this.sort;
     
    })  
  }
  

  ClearSelection()
  {
    this.searchValue = '';
    this.searchValueFormControl.setValue('');
    /*  this.txtTreeViewSelected.nativeElement.value='' ;  */
    this.getSelectedItemsList   = "Select Activity!";
    this.checklistSelection.clear();
    this.searchServ.getSearchActivityList(this.formInstance.value.businessControl.join(",")).subscribe((data1: any[])=>{
      console.log(data1);
      const data = this.treeConstruct(data1);

      this.dataSource_Tree.data = data;
      this.treeControl.dataNodes = data;
      Object.keys(this.dataSource_Tree.data).forEach(x => {
       this.setParent(this.dataSource_Tree.data[x], null);
     });

    this.dataChange.next(data);
    }); 
    this.showCloseIcon= false;
  }


  getSelectedItems(): string {
    
    if (!this.checklistSelection.selected.length) return "Select Activity";
    {
      
      return this.checklistSelection.selected.map(s => s.text).join(",");
        
    }
   
  }

  /* filterChanged(filterText: string) {
    
    console.log("filterChanged", filterText);
    // ChecklistDatabase.filter method which actually filters the tree and gives back a tree structure
    this._database.filter(filterText);
    if (filterText) {
      this.treeControl.expandAll();
    } else {
      this.treeControl.collapseAll();
    }
  } */


 

      addHyperlink(Data: string) {
        
        const jsonObject = JSON.parse(Data);
        var retValText = ""; 
    
        var retVal = "";
        for(var key in jsonObject) {    
            retValText=jsonObject[key].Text.replace('; ', ';<br/>'); 
                      
        }
        
        return retValText;
      }
      
      applyBgColorFontColorFontweightForColumns(worksheet: XLSX.WorkSheet, data: any[]):void{

        if(data.length >0){
// Assuming your data starts at row 2 because row 1 is headers
    const range: XLSX.Range = XLSX.utils.decode_range(worksheet['!ref']);
     for (let rowNum = range.s.r + 1; rowNum <= range.e.r; rowNum++) {
    const cellRef_bgColor = XLSX.utils.encode_cell({ r: rowNum, c: 0 });
    const cellRef_fontColor = XLSX.utils.encode_cell({ r: rowNum, c: 1 });
    const cellRef_bold = XLSX.utils.encode_cell({ r: rowNum, c: 2 });
    
    const colorCell: XLSX.CellObject = worksheet[cellRef_bgColor];
    const cell_fontColor : XLSX.CellObject = worksheet[cellRef_fontColor];
    const cell_bold : XLSX.CellObject = worksheet[cellRef_bold];
    let fontWeight: boolean;
    if(cell_bold != undefined){
      if(cell_bold.v.toString().toLowerCase() === 'bold'){
        fontWeight= true
      }else{
        fontWeight=false
      }
    }
    
    
    if (colorCell) {
        let color = colorCell.v; // Get the value
        if(color== 'White'){
          color='#FFFFFF';
        }
        color= color.toString().substring(1);
        // Apply the color to each cell in the row
        for (let colNum = range.s.c; colNum <= range.e.c; colNum++) {
            const cellAddress = XLSX.utils.encode_cell({ r: rowNum, c: colNum });
            
            if (!worksheet[cellAddress]) worksheet[cellAddress] = {};
            worksheet[cellAddress].s = {
              fill:{
                fgColor:{rgb:color}
              },
              font:{
                bold: fontWeight,
                color:{rgb:"000000"},
                name: 'ABBvoice',
              },
              alignment: {
                vertical: colNum ==11 ? 'left' :'center',
                horizontal: colNum ==11 ? 'left' :'center',
                wrapText: '1', // any truthy value here
              },
              border: {
                right: {
                  style: 'thin',
                  color: '000000',
                },
                bottom: {
                  style: 'thin',
                  color: '000000',
                },
                top: {
                  style: 'thin',
                  color: '000000',
                },
                left: {
                  style: 'thin',
                  color: '000000',
                },
              },
            };
            if(worksheet[cellAddress].l != undefined){
              worksheet[cellAddress].s = {
                fill:{
                  fgColor:{rgb:color}
                },
                font:{
                  bold: fontWeight,
                  color:{rgb:"0000FF"},
                  name: 'ABBvoice',
                  underline:true
                },
                alignment: {
                  vertical: 'center',
                  horizontal: 'center',
                  wrapText: '1', // any truthy value here
                },
                border: {
                  right: {
                    style: 'thin',
                    color: '000000',
                  },
                  bottom: {
                    style: 'thin',
                    color: '000000',
                  },
                  top: {
                    style: 'thin',
                    color: '000000',
                  },
                  left: {
                    style: 'thin',
                    color: '000000',
                  },
                },
              }
            }
        }
    }
}
        }
let dataLength : number;
if(data.length==0){ 
  dataLength = 3;
}else{
  dataLength = data.length;
}

for(let i = 0; i < dataLength; i++) {
for(let j = 0; j < 3; j++) {
delete worksheet[XLSX.utils.encode_cell({r: i,c: j })]; } }

for (let i = 0; i < dataLength + 1; i++) {
  const cellD = XLSX.utils.encode_cell({ r: i, c: 3 }); // Cell in column D
  const cellA = XLSX.utils.encode_cell({ r: i, c: 0 }); // Corresponding cell in column A
  worksheet[cellA] = worksheet[cellD]; // Move data
  delete worksheet[cellD]; // Delete original data in column D
 
  const cellE = XLSX.utils.encode_cell({ r: i, c: 4 }); // Cell in column D
  const cellB = XLSX.utils.encode_cell({ r: i, c: 1 }); // Corresponding cell in column A
  worksheet[cellB] = worksheet[cellE]; // Move data
  delete worksheet[cellE]; // Delete original data in column D
 
  const cellF = XLSX.utils.encode_cell({ r: i, c: 5 }); // Cell in column D
  const cellC = XLSX.utils.encode_cell({ r: i, c: 2 }); // Corresponding cell in column A
  worksheet[cellC] = worksheet[cellF]; // Move data
  delete worksheet[cellF]; // Delete original data in column D
 
  const cellG = XLSX.utils.encode_cell({ r: i, c: 6 }); // Cell in column D
  const cell_D = XLSX.utils.encode_cell({ r: i, c: 3 }); // Corresponding cell in column A
  worksheet[cell_D] = worksheet[cellG]; // Move data
  delete worksheet[cellG]; // Delete original data in column D

  const cellH = XLSX.utils.encode_cell({ r: i, c: 7 }); // Cell in column D
  const cell_E = XLSX.utils.encode_cell({ r: i, c: 4 }); // Corresponding cell in column A
  worksheet[cell_E] = worksheet[cellH]; // Move data
  delete worksheet[cellH]; // Delete original data in column D

  const cellI = XLSX.utils.encode_cell({ r: i, c: 8 }); // Cell in column D
  const cell_F = XLSX.utils.encode_cell({ r: i, c: 5 }); // Corresponding cell in column A
  worksheet[cell_F] = worksheet[cellI]; // Move data
  delete worksheet[cellI]; // Delete original data in column D

  const cellJ = XLSX.utils.encode_cell({ r: i, c: 9 }); // Cell in column D
  const cell_G = XLSX.utils.encode_cell({ r: i, c: 6 }); // Corresponding cell in column A
  worksheet[cell_G] = worksheet[cellJ]; // Move data
  delete worksheet[cellJ]; // Delete original data in column D

  const cellK = XLSX.utils.encode_cell({ r: i, c: 10 }); // Cell in column D
  const cell_H = XLSX.utils.encode_cell({ r: i, c: 7 }); // Corresponding cell in column A
  worksheet[cell_H] = worksheet[cellK]; // Move data
  delete worksheet[cellK]; // Delete original data in column D

  const cellL = XLSX.utils.encode_cell({ r: i, c: 11 }); // Cell in column D
  const cell_I = XLSX.utils.encode_cell({ r: i, c: 8 }); // Corresponding cell in column A
  worksheet[cell_I] = worksheet[cellL]; // Move data
  delete worksheet[cellL]; // Delete original data in column D

  const cellM = XLSX.utils.encode_cell({ r: i, c: 12 }); // Cell in column D
  const cell_J = XLSX.utils.encode_cell({ r: i, c: 9 }); // Corresponding cell in column A
  worksheet[cell_J] = worksheet[cellM]; // Move data
  delete worksheet[cellM]; // Delete original data in column D

  const cellN = XLSX.utils.encode_cell({ r: i, c: 13 }); // Cell in column D
  const cell_K = XLSX.utils.encode_cell({ r: i, c: 10 }); // Corresponding cell in column A
  worksheet[cell_K] = worksheet[cellN]; // Move data
  delete worksheet[cellN]; // Delete original data in column D

  const cellO = XLSX.utils.encode_cell({ r: i, c: 14 }); // Cell in column D
  const cell_L = XLSX.utils.encode_cell({ r: i, c: 11 }); // Corresponding cell in column A
  worksheet[cell_L] = worksheet[cellO]; // Move data
  delete worksheet[cellO]; // Delete original data in column D

  const cellP = XLSX.utils.encode_cell({ r: i, c: 15 }); // Cell in column D
  const cell_M = XLSX.utils.encode_cell({ r: i, c: 12 }); // Corresponding cell in column A
  worksheet[cell_M] = worksheet[cellP]; // Move data
  delete worksheet[cellP]; // Delete original data in column D

  const cellQ = XLSX.utils.encode_cell({ r: i, c: 16 }); // Cell in column D
  const cell_N = XLSX.utils.encode_cell({ r: i, c: 13 }); // Corresponding cell in column A
  worksheet[cell_N] = worksheet[cellQ]; // Move data
  delete worksheet[cellQ]; // Delete original data in column D

  const cellR = XLSX.utils.encode_cell({ r: i, c: 17 }); // Cell in column D
  const cell_O = XLSX.utils.encode_cell({ r: i, c: 14 }); // Corresponding cell in column A
  worksheet[cell_O] = worksheet[cellR]; // Move data
  delete worksheet[cellR]; // Delete original data in column D

  const cellS = XLSX.utils.encode_cell({ r: i, c: 18 });
  const cell_P = XLSX.utils.encode_cell({ r: i, c: 15 }); // Corresponding cell in column A
  worksheet[cell_P] = worksheet[cellS]; // Move data
  delete worksheet[cellS];
        }
          }
      reset(){
        
        this.selectedDivisionValues = [];
        this.selectedDesignationValues=[];
         this.selectedDivisionId.reset();
         this.selectedDesignationId.reset();
        this.searchDesignationTextboxControl.reset();
        this.searchDivisionTextboxControl.reset();
        this.searchBusinessAreaTextboxControl.reset();
        this.selectBusinessAreaFormControl.reset();
        this.selectDivisionFormControl.reset();
        this.selectDesignationFormControl.reset();
        this.checklistSelection.clear();
        this.getSelectedItemsList   ="Select Activity";
        this.searchValue = '';
        this.searchValueFormControl.setValue('');
        this.searchValueText='';
        this.allSelected=false
        this.allSelectedDivision=false;
        this.allSelectedDesignation=false;
        this.divisionList=[];
        this.designationList=[];
        this.divisionFilteredOptions= of(this.divisionList);
        this.designationFilteredOptions= of(this.designationList);
        this.dataSource_Tree.data=[];
        this.dataSource1 = new MatTableDataSource<EmployeeDetails>([]);
        this.formInstance.value.businessControl=[]
        for(let w = 0; w < this.workSheetTables.length; w++) 
          {
          this.workSheetTables[w].dataSource= new MatTableDataSource<EmployeeDetails>([]);
          }
          this.workSheetTables=[];
      }    
          
          



}