<div *ngIf="loginDisplay==false"  fxLayout="column" fxFlexFill>
	<mat-toolbar class="navbar-color mat-elevation-z8 p-0" fxLayout="row" fxLayoutAlign="start stretch" style="z-index: 2">
	  
	
  
	  <button mat-button routerLink="/" >
		<img src="./assets/img/abb-logo-33px.38cf73d.png">
	  </button>
  
	
  
	  <span fxFlex></span>
  
	  <div fxLayout="row"  >

		<button mat-button *ngIf="!loginDisplay"  (click)="login()">Login <mat-icon>login</mat-icon></button>
		</div>
		
	</mat-toolbar>
  
	

	<div class="main1">
		
		<br>
		<!-- <h1 class="Head">Demo </h1> -->
		<h1 class="Head" style="font-weight: 600;font-size:xx-large;  font-family: ABBvoice;padding-top: 25px!important;">Welcome to ABB Table Of Authority Tool</h1>
	  </div>
	 
  

  </div>


<div *ngIf="loginDisplay==true"  fxLayout="column" fxFlexFill>
	<mat-toolbar class="navbar-color mat-elevation-z8 p-0" fxLayout="row" fxLayoutAlign="start stretch" style="z-index: 2">
	  
	  <button mat-button (click)="MenuToggele()">
		<mat-icon>menu</mat-icon>
	  </button>
  
	  <button mat-button routerLink="/" >
		<img src="./assets/img/abb-logo-33px.38cf73d.png">
	  </button>
  
	  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px" class="pl-3">
		
		<span fxHide fxShow.md></span>
	
	  </div>
  
	  <span fxFlex></span>
  
	  <div fxLayout="row" fxShow="false" fxShow.gt-sm class="d-flex">
		<button mat-button *ngIf="reportIssue" (click)="ViewReportIssueDailogue()"><mat-icon matTooltip="Report Issue">support_agent</mat-icon></button>
		<!-- <button mat-button (click)="ViewProcessFlowDialog()"><mat-icon matTooltip="Process Flow">schema</mat-icon></button> -->
		<button mat-button (click)="UserManual()"><mat-icon matTooltip="User Manual">help_outline</mat-icon></button>
		<button mat-button [matMenuTriggerFor]="beforeMenu"> {{loginame}}   <mat-icon >account_circle</mat-icon></button>
		<mat-menu  #beforeMenu="matMenu" xPosition="before" >
			<mat-card style="width: 280px; margin-right: 5%; margin-top: -5%;" class="mat-elevation-z0">
			<div class="row" style="margin-top: 3%;">
			<div class="col-4"><ngx-avatar size="65" name={{loginame}}> </ngx-avatar>	</div>	
			<div class="col-4">
				<div class="row" style="padding-left: 8px;"> <p style="font-size: 14px;"> {{loginame}}  </p> </div>
				<div class="row" style="padding-left: 8px;"><h5 style="font-size: 15px;"> {{Email}} </h5></div>
			</div>


	
			</div>	

			</mat-card>
			<div class="row">
				<div class="col-12" style="padding-left: 30%;">
					<button mat-button *ngIf="loginDisplay" (click)="logout()"> 
						<mat-icon>logout </mat-icon>
						Sign out </button>
							</div>
						</div>
		  </mat-menu>
		
		
	  </div>
	 
	</mat-toolbar>

	<mat-drawer-container class="example-container mat-typography" fxFlex autosize class="background " [hasBackdrop]="false" [ngClass]="status===true ? 'opensidnav' : ''">

		<mat-drawer  #drawer1 mode="over"  opened="true" disableClose="true"  class="mobileHide"   >
			<mat-nav-list>
				<span *ngFor="let item of menuItems">
					<span *ngIf="item.children && item.children.length > 0">
					  <mat-accordion>
						<mat-expansion-panel>
						  <mat-expansion-panel-header>
							<mat-panel-title>							  
							  <div fxLayout="row" fxLayoutAlign="space-between center">
								<mat-icon mat-list-icon>{{item.menuIcon}}</mat-icon>
								<h4 style="font-family: ABBvoice;font-weight: 500;">{{item.menuTitle}}</h4>
							  </div>
							</mat-panel-title>
						  </mat-expansion-panel-header>
						  <span *ngFor="let child of item.children">
							<mat-list-item [routerLink]="child.menuLink" routerLinkActive="router-link-active">							 
							  <div fxLayout="row" fxLayoutAlign="space-between center">
								<mat-icon mat-list-icon>{{child.menuIcon}}</mat-icon>
								<h4 style="font-family: ABBvoice;font-weight: 500">{{child.menuTitle}}</h4>
							  </div>
							</mat-list-item>
						  </span>
						</mat-expansion-panel>
					  </mat-accordion>
					</span>				
				  
					<span *ngIf="!item.children || item.children.length === 0">
						<mat-list-item [routerLink]="item.menuLink" routerLinkActive="router-link-active" >
							<div fxLayout="row" fxLayoutAlign="space-between center">
								<mat-icon mat-list-icon>{{item.menuIcon}}</mat-icon>
								<h4 style="font-family: ABBvoice;font-weight: 500">{{item.menuTitle}}</h4>
						    </div>
						</mat-list-item>
					</span>
			    </span>
			</mat-nav-list>
		</mat-drawer>
	
	
		<div class="container main">
			<router-outlet></router-outlet>
			<span style="font-family: ABBvoice" class="version-info">© Copyright {{currentYear}} ABB INDIA LTD V1.0</span>
		  </div>
	</mat-drawer-container>

	
  

  </div>