import { Component, OnInit, Inject, OnDestroy, Injectable } from '@angular/core';

import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { CanDeactivate,ActivatedRouteSnapshot, Router, RouterStateSnapshot, NavigationEnd } from '@angular/router';
import { ApiservicesService } from 'src/app/Services/apiservices.service';
import { CanActivate } from '@angular/router/router';
import { Observable } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';


// declare interface menu {
//   menuLink?:string;
//   menuTitle: string;
//   menuIcon:string;
//   children?:menu[];
// }
// export const menu: menu[] = [];




const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me/photo/$value';
@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit,OnDestroy {
  menuItems! :any[];
  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();
  loginame!: string;
  Email: any;
  status=false;
  reportIssue: boolean = true;
currentYear: number=new Date().getFullYear();
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,public dialog: MatDialog,private http: HttpClient, private domSanitizer: DomSanitizer,private router:Router,
    public apisrv:ApiservicesService
  ) { }
  ngOnInit(): void {
   
    console.log("start");
    sessionStorage.setItem('countMatched',"true");
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
         
          this.apisrv.GetABBLogin(window.btoa(this.Email)).subscribe((data:any)=>{
            console.log(data);
            if(data.length > 0)
            {
              sessionStorage.setItem('menuTitle',data[0].menuTitle);
              sessionStorage.setItem('menuIcon',data[0].menuIcon);
              sessionStorage.setItem('menuLink',data[0].menuLink);
              sessionStorage.setItem('roleId',data[0].roleIdRef);
              sessionStorage.setItem('role',data[0].role);
              sessionStorage.setItem('LoginEmail',this.Email);
              sessionStorage.setItem('userId',data[0].userID);
              sessionStorage.setItem('userToken',data[0].userToken);
              sessionStorage.setItem('tokenExpiration',data[0].tokenExpiration);
              

              if(data.length >1)
              {
                sessionStorage.setItem('menuTitle2',data[1].menuTitle);
                sessionStorage.setItem('menuIcon2', data[1].menuIcon);
                sessionStorage.setItem('menuLink2',data[0].menuLink);
              }
              else
              {
                sessionStorage.setItem('menuTitle2',"");
                sessionStorage.setItem('menuIcon2', "");
                sessionStorage.setItem('menuLink2',"");

              }
              this.menuItems = data;
              this.router.navigateByUrl("\Search");
              this.reportIssue = true;
            }
            else{
              this.apisrv.ShowSnackBar("User not authorized, Please contact administrator",'red-snackbar'); 
              this.loginDisplay = false;
            }
          });
         
        
      
      });
  }

  
  setLoginDisplay() {
    
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    let data=this.authService.instance.getAllAccounts();
    console.log(data);
    this.loginame=data[0].name== null ? "" :data[0].name;
    this.Email=data[0].username== null ? "" :data[0].username;
  }

 MenuToggele() {
  this.status = !this.status;
}
UserManual(){
this.openFile("TOA-UserManual.pdf");   
}
openFile(fileName) {
window.open("assets/documents/" + fileName);
}
ViewProcessFlowDialog() {
const dialogRef = this.dialog.open(ProcessFlowDialog,{
  height: '680px',
  width: '1000px',data:{}

});

dialogRef.afterClosed().subscribe(result => {
  console.log(`Dialog result: ${result}`);
});

}
ViewReportIssueDailogue() {
  (window as any).open("https://abb.service-now.com/myservices?id=myis", "_blank");
}
  login() {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    }
  }

  logout() {

    sessionStorage.removeItem('menuTitle');
    sessionStorage.removeItem('menuIcon');
    sessionStorage.removeItem('menuLink');
    sessionStorage.removeItem('roleId');
    sessionStorage.removeItem('role');
    sessionStorage.removeItem('LoginEmail');
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('userToken');
    sessionStorage.removeItem('tokenExpiration');
    sessionStorage.removeItem('menuTitle2');
    sessionStorage.removeItem('menuIcon2');
    sessionStorage.removeItem('menuLink2');
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.authService.logoutPopup({
        postLogoutRedirectUri: "/",
        mainWindowRedirectUri: "/"
      });
    } else {
      this.authService.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    }
  }
  getProfile() {
    this.http.get(GRAPH_ENDPOINT,{responseType: "blob"})
      .subscribe((blob) => {
        
       
      
      });
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}

@Component ({
  selector: 'app-dialog',
  templateUrl: './reportIssueDialog.html',
  styleUrls: ['./side-nav.component.scss']})

  export class ReportIssueDialog {
    feedback: string;
    
         
    constructor(@Inject(MAT_DIALOG_DATA) public data: any,public apisrv:ApiservicesService,private formBuilder: FormBuilder,  public dialogRef: MatDialogRef<ReportIssueDialog>) {}; /*public apisrv:ApiservicesService */
    
    ngOnInit() {
    }  
    
    onsubmit(){
        var loginID = sessionStorage.getItem('loginID');
        
        if(this.feedback == undefined)
        {
          this.apisrv.ShowSnackBar("Feedback is required",'red-snackbar'); 
        }
        else if(this.feedback.trim() =="")
        {
          this.apisrv.ShowSnackBar("Feedback is required",'red-snackbar'); 
        }
        
    }    

    close(){
      this.dialogRef.close();
    }
   
  }

@Component ({
  selector: 'app-dialog',
  templateUrl: './ProcessFlowDialog.html',
  styleUrls: ['./side-nav.component.scss']})

  export class ProcessFlowDialog {

    feedback: string;
    
         
    constructor(@Inject(MAT_DIALOG_DATA) public data: any,public apisrv:ApiservicesService,private formBuilder: FormBuilder,  public dialogRef: MatDialogRef<ReportIssueDialog>) {}; /*public apisrv:ApiservicesService */
    
    ngOnInit() {
    }  
    
    


    close(){
      this.dialogRef.close();
    }
   
  }
